<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
               <b-card-code>
                  <validation-observer ref="simpleRules">
                    <form >
                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Bill Type</label>
                                <v-select
                                :options="['Bill Wise','Summary']"
                                placeholder="None"
                                v-modal="billtype"
                                @input="changeBilltype($event)"
                                />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Type</label>
                             <v-select
                                :options="['Customer','supplier']"
                                placeholder="None"
                                v-modal="type"
                                />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Party Name</label>
                             <v-select
                                label="name"
                                :options="PartyOptions"
                                placeholder="None"
                                v-modal="partyname"
                                />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                  </validation-observer>
              </b-card-code>
              <report-table
                :data="data"
                :columns="billtype=='Summary' ? Summary :fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ReportTable from '../../../components/ReportTable.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable
  },
  data () {
    return {
      data:'',
      partyname: '',
      type: '',
      billtype: 'Bill Wise',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem('accessToken'),
      data1: [],
      tcr:'',
      tdr:'',
      fields: [
        { field: 'id', label: 'S.R. No', hidden: true, filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'partyname', label: 'Name Of Part', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'billno', label: 'Bill No', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'mobile', label: 'Mobile', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'city', label: 'City', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'debit', label: 'Debit', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'credit', label: 'Credit', filterOptions: {enabled: true, placeholder: 'Search'} }
      ],
      Summary:[

        { field: 'id', label: 'S.R. No', hidden: true, filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'partyname', label: 'Name Of Part', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'accgroup', label: 'Account Group', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'cramount', label: 'Cr Amount', filterOptions: {enabled: true, placeholder: 'Search'} },
        { field: 'dramount', label: 'Dr Amount', filterOptions: {enabled: true, placeholder: 'Search'} }
      ],
      PartyOptions:[]
    }
  },
  mounted () {
    this.getParty()
  },
  methods: {
    async getParty () {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        url: `${this.baseApi}/ledger`
      }
      await axios(requestOptions)
        .then((response) => {
          this.PartyOptions = response.data.data.data
        })
        .catch((error) => console.log(error, 'error'))
    },
    changeBilltype (e) {
      this.billtype = e
    },
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async searchData (tableData) {
      const data = {
        startdate:this.startdate,
        enddate:this.enddate
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(data),
        url:`${this.baseApi}/outstandingreport`
      })
        .then((response) => {
          this.data = response.data.data
          if (this.billtype == 'Summary') {

            this.data.map((item) => {
              this.tcr += item.cramount
              this.tdr += item.dramount
            })
            this.data.push({
              id1: '',
              aa:'',
              accgroup: 'Total',
              cramount: this.tcr,
              dramount: this.tdr
            })

          }
          this.fields.map(async (item) => {
            if (item.type == 'dropdown') {
              const accessToken = localStorage.getItem('accessToken')
              const baseApi = process.env.VUE_APP_APIENDPOINT
              const requestOptionsUsers = {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                url: `${baseApi}/${item.url}`
              }

              await axios(requestOptionsUsers)
                .then((response) => {
                  response.data.data.data

                    // ? (this.data[Array(item.url)] = response.data.data.data)
                    ? (this.data1[item.url] = response.data.data.data)
                    : response.data.data
                      ? (this.data1[item.url] = response.data.data)
                      : (this.data1[item.url] = response.data)

                  this.data.map((data) => {
                    this.data1[item.url].map((data1) => {
                      if (data[item.field] == data1.id) {
                        data[item.field] = data1[item.responseValue]
                      }
                    })
                  })

                })

                .catch((error) => console.log(error, 'error'))
            }
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
